import React from "react";
import "./style.css";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";
import arrow from "./Assets/arrow.png";


function ProductVideo() {

  return (
    <>
    <div
      style={{position:'relative'}}
      className="container-fluid video_section py-5"
    >

      <div
        style={{color:'#fff'}}
        className="container text-left"
      >
        <h3 className="py-4 mb-5" style={{textAlign: 'center'}}>Deepsearch Labs enables your team by providing a complete suite of data engineering, algorithms, and enterprise-ready application.</h3>
        
        <div className="card">
          <div className="card-header" id={"heading-2"}>
            <h2 className="mb-0">
              <button className="btn btn-link text-left" type="button" data-toggle="collapse" data-target={"#collapse-2"} aria-expanded={false} aria-controls={"collapse-2"}>
                <img
                  className="card-img-top-left icons"
                  src={arrow}
                  alt="Card cap"
                />
                Application Features
              </button>
            </h2>
          </div>
          <div id={"collapse-2"} className={"collapse show"} aria-labelledby={"heading-2"} data-parent="#accordionExample">
            <div className="card-body text-left">
              <ul>
                <li>
                  <h4>Finding Hidden Patterns</h4> 
                  <p>Use our proprietary knowledge graph to uncover hidden patterns and insights from your data and ours, helping your team stay ahead of market trends and make informed decisions.</p>
                </li>
                <li>
                  <h4>Multimodal Insight Discovery</h4> 
                  <p>Seamlessly discover, analyse, and integrate insights from Excel files, PDFs, videos, and audio formats to gather the information you need intuitively from your internal data or our corpus of online data. Everything is referenced.</p>
                </li>
                <li>
                  <h4>Private Scraper</h4> 
                  <p>Search all available online information on any topic of your choice with a private scraper.</p>
                </li>
                <li>
                  <h4>Multimodal Content Generation</h4> 
                  <p>Create text, reports, videos, and podcasts tailored to your organisation's needs, including research papers, presentations, market reports, and automated insights.</p>
                </li>
                <li>
                  <h4>Trend Scoping</h4> 
                  <p>Predict and identify emerging trends in real time with our AI-driven analytics including sentiment analysis, providing you with forward-looking market intelligence.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header" id={"heading-0"}>
            <h2 className="mb-0">
              <button className="btn btn-link text-left" type="button" data-toggle="collapse" data-target={"#collapse-0"} aria-expanded={false} aria-controls={"collapse-0"}>
                <img
                  className="card-img-top-left icons"
                  src={arrow}
                  alt="Card cap"
                />
                Data engineering
              </button>
            </h2>
          </div>
          <div id={"collapse-0"} className={"collapse"} aria-labelledby={"heading-0"} data-parent="#accordionExample">
            <div className="card-body text-left">
              <p>We have written scrapers for all kinds of data to make them compatible with our LLMs and system for our application. You are now able to use them for your projects. We do this alongside consulting to ensure best results.</p>
              <ul>
                <li>
                  <h4>PDF scarper</h4> 
                  <p>Our PDF scraper reads the PDF the way a human does. It is an ensemble of vision and texts models, built in house. The scraper interprets tables and graphs also.</p>
                </li>
                <li>
                  <h4>Audio scraper with speaker mapping</h4> 
                  <p>Our audio scraper, transcribe audio, provides sentiment analysis on audio file and maps speakers accurately.</p>
                </li>
                <li>
                  <h4>Web scraper</h4> 
                  <p>Our generalised crawler can crawl the Web for any information you require. From websites to generic search on keyword, the can adapt to any format.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header" id={"heading-1"}>
            <h2 className="mb-0">
              <button className="btn btn-link text-left" type="button" data-toggle="collapse" data-target={"#collapse-1"} aria-expanded={false} aria-controls={"collapse-1"}>
                <img
                  className="card-img-top-left icons"
                  src={arrow}
                  alt="Card cap"
                />
                Algorithm
              </button>
            </h2>
          </div>
          <div id={"collapse-1"} className={"collapse"} aria-labelledby={"heading-1"} data-parent="#accordionExample">
            <div className="card-body text-left">
              <p>If you already have got data  you can use our algorithms for different purposes. Same algorithms are used in our application. We provide this with consultation.</p>
              <ul>
                <li>
                  <h4>Report Generation</h4> 
                  <p>We  have multiagent  compounding LLMs, that can provide you with ondemands report generation.</p>
                </li>
                <li>
                  <h4>Sentiment signals</h4> 
                  <p>We can provide you with sentiment signals across all our corpus of online data and your internal data on any keywords regardless of type going back 5 years.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header" id={"heading-3"}>
            <h2 className="mb-0">
              <button className="btn btn-link text-left" type="button" data-toggle="collapse" data-target={"#collapse-3"} aria-expanded={false} aria-controls={"collapse-3"}>
                <img
                  className="card-img-top-left icons"
                  src={arrow}
                  alt="Card cap"
                />
                Consulting services
              </button>
            </h2>
          </div>
          <div id={"collapse-3"} className={"collapse"} aria-labelledby={"heading-3"} data-parent="#accordionExample">
            <div className="card-body text-left">
              <ul>
                <li>
                  <h4>Automate Workflow</h4> 
                  <p>Using our algorithms and scrapers automate workflow for your teams and create customized application. We can help you create best in house software.</p>
                </li>
                <li>
                  <h4>Data Engineering</h4>
                  <p>Linking all your data in one place regardless of type and location of the data.</p>
                  <p>Building and maintaining scraper pipelines to keep you updated: PDF scraping, audio, web based.</p> 
                  <p>Optimizing complex internal data for better search capabilities.</p>
                  <p>Creating vector and graph databases for effective AI use.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>

    {/* <div
      style={{position:'relative'}}
      className="container-fluid video_section py-5"
    >
        <ParticlesAnimation id='p-works' />
        <div className="row py-5">
          <div className="col-md-7 px-5">
            <iframe className="iframe-video" width="100%" src="https://www.youtube.com/embed/4KIMvobZWO8" frameborder="0" allowfullscreen></iframe>
          </div>
          <div className="col-md-5 px-5" style={{color:'#fff', textAlign: 'left'}}>

            <h2 className="py-2">Linking internal and external data for a holistic view is challenging.</h2>
            <p>Organizations produce internal data that is often fragmented and incomplete, making it difficult to link to public sources.</p>
            <p>As a result, using AI models can be unreliable.</p>
            <br />
            <h2 className="py-2">Introducing DeepSearch Labs</h2>
            <p>Our AI search engine swiftly analyzes millions of sources, revealing patterns through a map of information.</p> 
            <p>DSL contextualizes this map, using Large Language Models, without producing any hallucinations.</p>
            <p>We have prioritized your privacy and control above everything.</p>
            
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ProductVideo;
