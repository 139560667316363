import React from "react";
import "./style.css";
import flish from "./Assets/expand_circle_right.svg";
import { Link } from "react-router-dom";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";
import { useQuery, gql } from "@apollo/client";
import SpinnerComponent from "../../../components/Spinner/SpinnerComponent";
import NotFound from "../../../components/Not Found/NotFound";

const POSTS = gql`
  query GetSolutions {
    solutions {
      data {
        id
        attributes {
          name
          icon {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

function Solutions({title, description, style = 1}) {
  const { loading, error, data } = useQuery(POSTS);

  if (loading) return <SpinnerComponent />;
  if (error) return <NotFound />;

  const _title = title? title : "SOLUTIONS";
  const _description = description? description : "Data-Driven Decisions Across Industries";

  return (
    <section style={{position:'relative'}} id="solution_section" className="container-fluid solution_section">
      {/* <ParticlesAnimation id='p-banner' /> */}
      <div className="container py-5 _solutions">
        {style == 1 ? (<div className="row">
          <div className="col-12 d-flex flex-column align-items-start py-5 px-0">
            <p className="first-title">{_title}</p>
            <h1 className="second-title text-left">{_description}</h1>
          </div>
        </div>) : (<div className="row second-header">
          <div className="col-12 d-flex flex-column text-center py-5 px-0">
            <h1>{_title}</h1>
            <p>{_description}</p>
          </div>
        </div>)}
        <div className="row pb-4">
          <div className="container-fluid text-left">
            <div className="row justify-content-center">
              {data.solutions.data.map(solution => (
                <div className="col-md-4 p-4 solution" key={solution.id}>
                  <div className="p-4" key={solution.id}>
                    <img
                      className="img-rounded solution-imgs"
                      src={solution.attributes.icon?.data?.attributes?.url}
                      alt="Card cap"
                    />
                    <p className="solution-name">{solution.attributes.name}</p>
                    <div className="solution-more">
                      <Link to={"/solution/" + solution.id} style={{ textDecoration: "none" }}>
                        <img
                          className="img-rounded solution-imgs"
                          src={flish}
                          alt="Card cap"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solutions;
