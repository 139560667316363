import React, {useMemo} from "react";
import "./style.css";
import Icon1 from "./Assets/icon1.svg";
import Icon2 from "./Assets/icon2.svg";
import Icon3 from "./Assets/icon3.svg";
import Icon4 from "./Assets/icon4.svg";
import Icon5 from "./Assets/icon5.svg";
import Icon6 from "./Assets/icon6.svg";
import ParticlesAnimation from "../../../components/Particles animation/ParticlesAnimation";
function WhyDeepSearchLabs() {
  return (
    <>
      <div style={{position:'relative'}}  className="container-fluid mt-5 why-dsl-section">
        {/* <ParticlesAnimation id='p-cases' /> */}
        <div className="container py-5">
          <div className="row justify-content-start">
            <div className="align-items-start">
              <p className="first-title text-left">BENEFITS</p>
              <h1 className="second-title text-left">Why Choose Deepsearch Labs?</h1>
              <p style={{textAlign: 'left', fontWeight: '600'}}>We bring cutting-edge technology to your business, enabling you to gain actionable insights from your internal data, with our corpus of online data swiftly. Our platform is tailored to meet the needs of various industries, offering:</p>
            </div>
          </div>
          <div className="row mt-5 pt-3 justify-content-center">
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon4}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Visualization
                  </h5>
                  <p className="card-text text-justify">
                    Visualizing insights into a map. This map of information can then be queried using our private and secure Language model, or turned into a multimodal output.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon6}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Turn data into actionable content
                  </h5>
                  <p className="card-text text-justify">
                    DeepSearch Labs can turn the map of information into AI generated insightful research reports, sentiment signals, trends, Summaries, short videos, and podcasts. Our contents excel at research depth, writing quality, translation, false positives avoidance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon5}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Quickly Customize the results
                  </h5>
                  <p className="card-text text-justify">
                    Customize your experience with our engine by adding your internal data in raw format. There is no need to hire data engineers. We work with your organization to develop tailored solutions based on your unique data needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon1}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Secure & Scalable
                  </h5>
                  <p className="card-text text-justify">
                    Our platform is built with enterprise-level security and scalability, ensuring that your data remains protected as your organization grows.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon2}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                    Save time
                  </h5>
                  <p className="card-text text-justify">
                    Let our AI technology do the work for you. Spend less time on time-consuming, manual tasks and more time on what you do best: Analysis and Decision Making.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-transparent border-0 py-4 mt-2 mt-md-0">
                <img
                  className="card-img-top-left icons"
                  src={Icon3}
                  alt="Card cap"
                />
                <div className="card-body text-left">
                  <h5 className="card-title py-4">
                  Expert Support
                  </h5>
                  <p className="card-text text-justify">
                    We provide ongoing support and consultation to ensure that your intelligence platform is always optimized for performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyDeepSearchLabs;
