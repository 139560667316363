import React, { useEffect } from "react";
import "./style.css";
import { useLocation, useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import NotFound from "../../components/Not Found/NotFound";
import { Helmet } from "react-helmet-async";
import Solutions from "../../website sections/Home Page Sections/Solutions/Solutions";
import Banner from "../../website sections/Home Page Sections/Banner/Banner";
import Contact from "../../website sections/Home Page Sections/Contact/Contact";
import { Link } from "react-router-dom";



function SolutionsPage() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const title = "Solutions";
  const description = "At DeepSearch Labs, our powerful AI search engine provides game-changing insights for organizations across industries - from finance to consulting and beyond. Click on any solution below to learn how our platform can give you a competitive edge in your market.";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />
        <link rel="canonical" href={pathname} />
      </Helmet>


      <Solutions title={title} description={description} style={2} />
      {/* <Banner /> */}
      <Contact />

    </>
  );
}

export default SolutionsPage;
